import BasePaper from "./BasePaper";
import Box from '@mui/material/Box';

export default function Join(props){
    return(<>
        <BasePaper 
            mode={props.mode}
            title="参加の注意事項"
            anchor="join"
            body={<>
                <Box sx={{m:1}}>
                    <ul>
                        <li>近隣にお住まいの方の迷惑になりますので、午前9時以前のご来場はおやめください。</li>
                        <li>駐車場はありません。公共交通機関をご利用ください。</li>
                        <li>一般参加者については入場の際、カタログが必要です。</li>
                        <li>カタログの当日頒布価格は500円です。</li>
                        <li>当日はスタッフの指示に従って下さい。<br />
                        スタッフの指示を守らなかったり、 スタッフの業務の妨げとなる行為は会場を退出していただく場合がございます。</li>
                        <li> 走る・座り込む・飲酒・喫煙等の行為は禁止です。</li>
                        <li> コスプレおよびカメラ撮影には別途登録が必要です。<br />コスプレ参加案内およびカメラ登録案内の内容を確認の上、当日受付までお申し付けください。</li>
                        <li> 一般参加者がCD・同人誌・その他グッズを会場内で頒布する行為は有償・無償問わず禁止です。</li>
                        <li>  『(会場内を)汚さない』『怪我をさせない』『人を不快にさせない』を守って下さい。</li>
                        <li>  ゴミは各自でお持ち帰りください。</li>
                        <li>  通路で立ち止まるのも迷惑となる場合があるのでおやめ下さい。</li>
                        <li>  何か判らない事がありましたら準備会メールへお問い合わせ頂くか、 イベントXアカウント『@kasane_only』へリプライを下さい。</li>
                    </ul>
                </Box>
            </>}
        />
    </>)
}