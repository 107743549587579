import Typography from '@mui/material/Typography';

export default function TitleText(props){
    return(<>
        <Typography variant="h4"  id={props.anchor !==undefined ? props.anchor: "paper"}>
        <img src='static/img/drillU.png' style={{height:40,objectFit:'contain'}} alt="section_decoration"/>
        {props.title}
        <img src={props.mode==='light' ? 'static/img/drillS.png' : 'static/img/drillU.png'} style={{height:40,objectFit:'contain'}} alt="section_decoration" />
        </Typography>
    </>)
}