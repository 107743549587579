import Typography from '@mui/material/Typography';
import Link from "@mui/material/Link";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import BasePaper from "./BasePaper";

export default function About(props){
    return(<>
        <BasePaper 
            mode={props.mode}
            title="テトテリトリー"
            caption="重音テトオンリー同人即売会"
            anchor="about"
            body={<>
                <List>
                    <ListItem>
                        <Typography variant="body1">
                            開催日時：2024年2月3日(土) 11:00 ～ 15:00<br />
                            サークル入場：10:00
                        </Typography>
                    </ListItem>
                    <ListItem>
                        <Typography variant="body1">
                            場所：神奈川県　川崎産業振興会館
                            (<Link color="inherit" href="https://kawasaki-sanshinkaikan.jp/">会場サイト</Link>)
                            (<Link color="inherit" href="https://www.google.co.jp/maps/place/%E5%B7%9D%E5%B4%8E%E5%B8%82%E7%94%A3%E6%A5%AD%E6%8C%AF%E8%88%88%E4%BC%9A%E9%A4%A8/@35.5342781,139.6979976,17z/data=!4m6!3m5!1s0x60186099fb404b45:0xaf9a6f040b65ff4c!8m2!3d35.5354607!4d139.7010648!16s%2Fg%2F1tfgd6dd?hl=ja&entry=ttu">Google Map</Link>)
                        </Typography>
                    </ListItem>
                    <ListItem>
                        <Typography variant="body1">
                            サークル数：直接参加のみ50SP
                        </Typography>
                    </ListItem>
                </List>
            </>}
        />
    </>)
}