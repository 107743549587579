import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import ModeNightIcon from '@mui/icons-material/ModeNight';
import LightModeIcon from '@mui/icons-material/LightMode';
import Box from '@mui/material/Box';
import { blueGrey} from '@mui/material/colors';
import { useCookies } from "react-cookie";

export default function Header(props){
    const path = window.location.protocol + "//" + window.location.hostname + (window.location.port ==="" ? "" : ":" +window.location.port)
    const [cookies, setCookie, removeCookie] = useCookies(['']);
    setCookie("mode",props.mode)
    return(
    <AppBar sx={{background:blueGrey[900]}} position='relative'>
        <Toolbar sx={{justifyContent:'space-between'}}>
            <Button variant='text' color='inherit' startIcon={<Avatar src={path+"/static/img/logo192.png"} />} href={path}>
            <Typography variant="h6">
                テトテリトリー
            </Typography>
            </Button>
            <Box>
                <IconButton onClick={props.colorMode.toggleColorMode}>
                    {props.mode==='light' ? <>
                        <LightModeIcon sx={{color:'#fff'}}/>
                    </>:<>
                        <ModeNightIcon />
                    </>}
                </IconButton>
            </Box>
        </Toolbar>
      </AppBar>)
}