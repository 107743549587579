import { grey,red,indigo,blueGrey } from '@mui/material/colors';

export const getDesignTokens = (mode) => (
    {
    palette: {
      mode,
      ...(mode === 'light'
        ? {
            // palette values for light mode
            primary:red,
            secondary:indigo,
            text: {
              primary: grey[900],
              secondary: grey[800],
            },
            background: {
              default: grey[200],
              paper: grey[200],
            },
          }
        : {
            // palette values for dark mode
            primary:red,
            secondary:indigo,
            text: {
              primary: '#fff',
              secondary: grey[500],
            },
            background: {
              default: blueGrey[900],
              paper: blueGrey[900],
            },
          }),
    },
  });