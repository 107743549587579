import Typography from '@mui/material/Typography';
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import BasePaper from "./BasePaper";
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import { Button } from '@mui/material';

export default function Circle(props){
    const path = window.location.protocol + "//" + window.location.hostname + (window.location.port ==="" ? "" : ":" +window.location.port)
    return(<>
        <BasePaper 
            mode={props.mode}
            title="サークル参加"
            anchor="circle"
            body={<>
                <Box sx={{m:1}}>
                <Typography variant="h5">申込締切</Typography>
                    <List>
                        <ListItem>
                            <Typography variant="body1">
                                1次締切	：	2023年12月8日(金) 募集完了 全員当選
                            </Typography>
                        </ListItem>
                        <ListItem>
                            <Typography variant="body1">
                                最終締切	：	2024年1月5日(金)<br />
                                毎週金曜日23:59時点でサークル当落判断を行い、当選サークル数が定数を超えればその時点で募集を終了します。
                            </Typography>
                        </ListItem>
                    </List>
                </Box>
                <Divider />
                <Box sx={{m:1}}>
                <Typography variant="h5">募集スペース数</Typography>
                    <List>
                        <ListItem>
                            <Typography variant="body1">
                            直接参加のみ 50SP
                            </Typography>
                        </ListItem>
                        <ListItem>
                            <Typography variant="body1">
                                参加費用1SP	3,500円(机1/2台	椅子1脚)
                            </Typography>
                        </ListItem>
                        <ListItem>
                            <Typography variant="body1">
                                参加費用2SP	7,000円(机1台		椅子2脚)
                            </Typography>
                        </ListItem>
                        <ListItem>
                            <Typography variant="body1">
                                追加椅子	   500円
                            </Typography>
                        </ListItem>
                    </List>
                </Box>
                <Divider />
                <Box sx={{m:1}}>
                    <Typography variant="h5">参加条件</Typography>
                    <ol>
                        <li>
                            <Typography variant="body1">
                                サークル代表が義務教育を修了していること
                            </Typography>
                        </li>
                        <li>
                            <Typography variant="body1">
                                『重音テトオリジナル曲及びそのn次創作物』又は『重音テトを用いたn次創作物』又は『重音テト又はその周辺ソフトウェアに 関する情報誌』を1点以上頒布又は展示をすること
                            </Typography>
                        </li>
                        <li>
                            <Typography variant="body1">
                                『頒布、展示をされる楽曲、創作物』は使用ツールや元となった創作物のライセンスを 遵守している、又は権利関係がクリアになっていること
                            </Typography>
                        </li>
                    </ol>
                </Box>
                <Divider />
                <Box sx={{m:1}}>
                    <Typography variant="h5">申込までの流れ</Typography>
                    <ol>
                        <li>
                            <Typography variant="body1">
                                テンプレートを活用して、サークルカットを作成する。
                            </Typography>
                        </li>
                        <li>
                            <Typography variant="body1">
                                下記の「サークル参加を申し込む」ボタンをクリックし、表示されるフォームに必要な情報を入力する。
                            </Typography>
                        </li>
                        <li>
                            <Typography variant="body1">
                                サークル参加申込仮受付メールに記載された口座に参加費を振り込む
                            </Typography>
                        </li>
                    </ol>
                </Box>
                <Button disabled color='primary' fullWidth variant='contained' href="https://docs.google.com/forms/d/e/1FAIpQLScmQv-7vnnNzUnGley9CxgHwGjfez5N6BEmtZXH7Iieohw1qg/viewform?usp=sf_link">
                    <Typography variant="h5">サークル参加受付は終了しました</Typography>
                </Button>
                <Divider />
                <Box sx={{m:1}}>
                    <Typography variant="h5">サークルカット作成のルール</Typography>
                    <ul>
                        <li>
                            <Typography variant="body1">
                                カラー 8bitグレースケール（モノクロ256階調）
                            </Typography>
                        </li>
                        <li>
                            <Typography variant="body1">
                                解像度 600dpi（pixel/inch）で寸法 70mm×70mm(1スペース・2スペース共に寸法は同じ)
                            </Typography>
                        </li>
                        <li>
                            <Typography variant="body1">
                                ファイル形式 PSD（Adobe Photoshop）、PNG、TIFFのいずれか<br />
                                PSDで提出する場合、事前にレイヤーを統合しておく。
                            </Typography>
                        </li>
                        <li>
                            <Typography variant="body1">
                                カット内のどこかに必ずサークル名を入れる
                            </Typography>
                        </li>
                    </ul>
                    <img src='./static/img/sample_sc.png' alt='サークルカットサンプル' style={{maxWidth:'100%'}}/>
                </Box>
                <Button color='primary' fullWidth variant='contained' href={path + "/static/template.zip"}>
                    <Typography variant="h5">テンプレートダウンロード</Typography>
                </Button>
                <Divider /><br />
            </>}
        />
    </>)
}