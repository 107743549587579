import * as React from 'react';
import './App.css';
import { Routes, Route } from 'react-router-dom';
import {getDesignTokens} from './theme.js'
import { useCookies } from "react-cookie";//<--import

import useMediaQuery from '@mui/material/useMediaQuery';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

import Header from './Header';
import Footer from './Footer';
import Top from './Top';
import Privacy from './Privacy';
import CircleList from './CircleList';

function App() {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const [cookies, setCookie, removeCookie] = useCookies(['mode']);
  const mode_ = (cookies.mode !== undefined ? cookies.mode : (prefersDarkMode ? 'dark' : 'light'))
  const [mode, setMode] = React.useState(mode_);
  const colorMode = React.useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
      },
    }),
    [],
  );
  const theme = React.useMemo(() => createTheme(getDesignTokens(mode)), [mode]);
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Header mode={mode} colorMode={colorMode}/>
      <Routes>
        <Route path="/" element={<Top mode={mode}/>} />
        <Route path="/privacy" element={<Privacy mode={mode}/>} />
        <Route path="/circlelist" element={<CircleList mode={mode}/>} />
      </Routes>
      <Footer theme={theme} mode={mode} />
    </ThemeProvider>
  );
}

export default App;
