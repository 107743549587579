import BasePaper from "./BasePaper";
import Box from '@mui/material/Box';
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Link from "@mui/material/Link";
import Typography from '@mui/material/Typography';

export default function LinkOther(props){
    return(<>
        <BasePaper 
            mode={props.mode}
            title="関連リンク"
            anchor="link_other"
            body={<>
                <Box sx={{m:1}} >
                <Typography variant="h5">関連イベント</Typography>
                    <List>
                        <ListItem>
                            <Typography variant="body1">
                            <Link variant="body1" href="https://tetofesofficial.wixsite.com/retetofes">
                                TETOFES
                            </Link>
                            (
                            <Link variant="body1" href="https://twitter.com/TETOFES">
                                公式Xアカウント
                            </Link>
                            )
                            </Typography >
                        </ListItem>
                        <ListItem>
                            <Typography variant="body1">
                            <Link variant="body1" href="https://twipla.jp/events/567638">
                                テトクラ
                            </Link>
                            (
                            <Link variant="body1" href="https://twitter.com/Tetoclub0401">
                                公式Xアカウント
                            </Link>
                            )
                            </Typography >
                        </ListItem>
                    </List>
                </Box>
                <Box sx={{m:1}}>
                <Typography variant="h5">重音テト</Typography>
                    <List>
                        <ListItem>
                            <Typography variant="body1">
                            <Link variant="body1" href="https://kasaneteto.jp/">
                                重音テト・オフィシャルサイト
                            </Link>
                            </Typography >
                        </ListItem>
                    </List>
                </Box>
                <Box sx={{m:1}}>
                <Typography variant="h5">支援印刷所</Typography>
                    <List>
                        <ListItem>
                            <Typography variant="body1">
                            <Link variant="body1" href="https://www.marusho-ink.co.jp/">
                                丸正インキ
                            </Link>
                            </Typography >
                        </ListItem>
                    </List>
                </Box>
            </>}
        />
    </>)
}