import Paper from "@mui/material/Paper";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import FootDress from "./FootDress";
import TitleText from "./TitleText";

export default function BasePaper(props){
    return(<>
        <Paper  display='block' sx={{flexGrow:1,m:2}} elevation={3}>
            {props.breads !== undefined &&<>{props.breads}</>}
            <Box sx={{p:2}}>
                <TitleText title={props.title} mode={props.mode} anchor={props.anchor}/>
                {props.caption !== undefined && <>
                    <Typography variant="caption">
                        {props.caption}
                    </Typography>
                </>}
                <Divider />
                {props.body}
                <Divider />
            </Box>
            <FootDress mode={props.mode}/>
        </Paper>
        <br />
    </>)
}