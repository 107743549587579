import useMediaQuery from '@mui/material/useMediaQuery';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Link from '@mui/material/Link';
import FootDress from './FootDress';

export default function Footer(props){
    const theme = props.theme;
    const matches = useMediaQuery(theme.breakpoints.up('sm'));
    const path = window.location.protocol + "//" + window.location.hostname + (window.location.port ==="" ? "" : ":" +window.location.port)
    return(
        <>
            <Divider />
                <Box sx={{ justifyContent:'space-between',display:matches ? 'flex' : 'block',p:2}}>
                    <Box sx={{flex: 1, order:1}}>
                        <Typography>
                        <Link variant='body2' color="inherit" href={path+"/privacy"}>プライバシーポリシー</Link> <br />
                        <Link variant='body2' color="inherit" href="https://twitter.com/kasane_only">公式Xアカウント</Link> <br />
                        <br />
                        <br />
                        </Typography>
                    </Box>
                    <Box sx={{textAlign:matches ? 'right' : 'left',flex: 1,order:matches ? 3 : 2}}>
                        <Typography variant='body2'>
                            キービジュアル：まつしまつき<br /><br />
                        </Typography>
                        <Typography variant='caption'>
                            重音テトは『ツインドリル』の管理キャラクターです。<br />
                            重音テトに関する権利は
                            線 さま(ビジュアルデザイン)と
                            小山乃舞世 さま(音声ライブラリー)に帰属します。
                        </Typography>
                        <br />
                        <br />
                    </Box>
                    <Box sx={{textAlign:matches ? 'center' : 'left',flex: 1,order:matches ? 2 : 3}}>
                        <Typography variant='body2'>
                        テトテリトリー準備会<br />
                        <Link href="mailto:kasane.teto.only@gmail.com">e-mail:kasane.teto.only@gmail.com</Link><br />
                        きみがため
                        <br />
                        <br />
                        </Typography>
                    </Box>
                </Box>
                <FootDress mode={props.mode} />
        </>
    )
}