import BasePaper from "./BasePaper";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Link from "@mui/material/Link";

export default function Cosplay(props){
    const path = window.location.protocol + "//" + window.location.hostname + (window.location.port ==="" ? "" : ":" +window.location.port)
    return(<>
        <BasePaper 
            mode={props.mode}
            title="コスプレ・カメラ参加案内"
            anchor="cosplay"
            body={<>
                <Box sx={{m:1}}>
                <Typography variant="h5">コスプレ参加案内</Typography>
                    <ul>
                        <li>コスプレ登録は当日登録となります。</li>
                        <li>コスプレは『重音テトおよびその亜種キャラクター』に限定させていただきます。</li>
                        <li>登録料は500円です。</li>
                        <li>コスプレしたままの来場や帰宅は堅くお断りいたします。</li>
                        <li>川崎産業振興会館内であっても、イベント会場外をコスプレしたまま移動することはできません。</li>
                        <li> 着替えは指定の<Link href={path + "/static/haichizu.pdf"} target="_blank">更衣室</Link>でお願いいたします。</li>
                        <li> クロークは用意していません。</li>
                        <li> メイクをする際は更衣室を『絶対に汚さない』ようお願いします。汚された場合は原状復帰にかかる実費を請求する場合があります。</li>
                        <li>  『(会場内を)汚さない』『怪我をさせない』『人を不快にさせない』を守って下さい。</li>
                        <li>  その他当日のスタッフの指示には必ず従ってください。</li>
                        <li>  何か判らない事がありましたら準備会メールへお問い合わせ頂くか、 イベントXアカウント『@kasane_only』へリプライを下さい。</li>
                    </ul>
                <Typography variant="h5">カメラ参加案内</Typography>
                    <ul>
                        <li>コスプレのカメラ撮影をされる方は撮影登録が必要となります。<br />（スマートフォン等での撮影を含む）</li>
                        <li>当日登録のみで登録料は500円となります。<br />（コスプレ登録をされた方についてはカメラ登録料は無料とします。）</li>
                        <li>撮影は<Link href={path + "/static/haichizu.pdf"} target="_blank">指定された場所</Link>で行ってください。</li>
                        <li>撮影の際は、必ず被写体に許可を取ってから撮影してください。<br />被写体の許可の無い盗撮やそれに類する行為が発覚した場合、スタッフ立会いのもと、その場でネガの没収、データの消去を行わせていただきます。</li>
                        <li>お連れさまの撮影のみの場合でも、必ずカメラ登録を行ってください。</li>
                        <li>その他当日のスタッフの指示には必ず従ってください。</li>
                    </ul>
                </Box>
            </>}
        />
    </>)
}