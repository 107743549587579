import Typography from '@mui/material/Typography';
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import BasePaper from "./BasePaper";
import Link from "@mui/material/Link";

export default function News(props){
    const path = window.location.protocol + "//" + window.location.hostname + (window.location.port ==="" ? "" : ":" +window.location.port)
    return(<>
        <BasePaper 
            mode={props.mode}
            title="お知らせ"
            anchor="news"
            body={<>
                <List>
                    <ListItem>
                        <Typography variant="body1">
                            2024/01/18(木) 参加の注意事項にカタログの当日頒布価格および当日の注意事項を追記しました。<br />
                            　　　　　　　コスプレ参加・カメラ参加案内を追加しました。<br />
                            　　　　　　　会場配置図に、更衣室・コスプレ撮影スペースを追加しました。<br />
                            　　　　　　　関連リンクに支援印刷所を追加しました。<br />
                        </Typography>
                    </ListItem>
                    <ListItem>
                        <Typography variant="body1">
                            2024/01/09(火) イベント概要に時間を追加しました。<br />
                        </Typography>
                    </ListItem>
                    <ListItem>
                        <Typography variant="body1">
                            2024/01/07(日) <Link href={path+"/circlelist"}>サークルリスト・配置図</Link>を公開しました。<br />
                        </Typography>
                    </ListItem>
                    <ListItem>
                        <Typography variant="body1">
                            2023/12/10(日) 12/8(金)時点の<Link href={path+"/circlelist"}>サークルリスト</Link>を公開しました。<br />
                        </Typography>
                    </ListItem>
                    <ListItem>
                        <Typography variant="body1">
                            2023/10/9(月) サークル募集を開始しました!<br />
                        </Typography>
                    </ListItem>
                    <ListItem>
                        <Typography variant="body1">
                            2023/10/9(月) webサイト公開しました!<br />
                        </Typography>
                    </ListItem>
                </List>
            </>}
        />
    </>)
}