import * as React from 'react';
import Box from '@mui/material/Box';
import About from './About';
import News from './News';
import Circle from './Circle';
import Join from './Join';
import LinkOther from './LinkOther';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import SubjectIcon from '@mui/icons-material/Subject';
import PeopleIcon from '@mui/icons-material/People';
import AnnouncementIcon from '@mui/icons-material/Announcement';
import LinkIcon from '@mui/icons-material/Link';
import InfoIcon from '@mui/icons-material/Info';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import { Menu } from '@mui/icons-material';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import { Typography } from '@mui/material';
import Cosplay from './Cosplay';

export default function Top(props){
    const { children, window } = props;
    const [hidden, setHidden] = React.useState(false);
    const trigger = useScrollTrigger({
      target: window ? window() : undefined,
      disableHysteresis: true,
      threshold: 100,
    });
    const handleClick = (event,value) => {
      const anchor = document.querySelector(
        value,
      );
  
      if (anchor) {
        anchor.scrollIntoView({
          block: 'start',
          behavior: "smooth",
        });
      }
    };
    return(<>
        <img src='./static/img/flier.png' alt='キービジュアル' style={{width:'100%'}}/>
        <Box sx={{justifyContent:'center'}}>
            <About mode={props.mode}  id="about"/>
            <News mode={props.mode}  id="news"/>
            <Circle mode={props.mode}  id="circle"/>
            <Join mode={props.mode}  id="join"/>
            <Cosplay mode={props.mode}  id="cosplay"/>
            <LinkOther mode={props.mode} />
        </Box>
        <Box sx={{ position: 'fixed',right:0,bottom:0,m:2}}>
            <SpeedDial
                ariaLabel="menu"
                hidden={hidden}
                icon={<Menu sx={{color:'#fff'}}/>}
                direction='up'
            >
            <SpeedDialAction
                key="link_other"
                icon={<LinkIcon />}
                tooltipTitle={"関連リンク"}
                tooltipOpen
                onClick={(e)=>handleClick(e, "#link_other")}
            />
            <SpeedDialAction
                key="cosplay"
                icon={<CameraAltIcon />}
                tooltipTitle={"コスプレ・カメラ参加案内"}
                tooltipOpen
                onClick={(e)=>handleClick(e, "#cosplay")}
            />
                <SpeedDialAction
                    key="join"
                    icon={<AnnouncementIcon />}
                    tooltipTitle={"参加の注意事項"}
                    tooltipOpen
                    onClick={(e)=>handleClick(e, "#join")}
                />
                <SpeedDialAction
                    key="circle"
                    icon={<PeopleIcon/>}
                    tooltipTitle={"サークル参加"}
                    tooltipOpen
                    onClick={(e)=>handleClick(e, "#circle")}
                />
                <SpeedDialAction
                    key="news"
                    icon={<SubjectIcon />}
                    tooltipTitle={"お知らせ"}
                    tooltipOpen
                    onClick={(e)=>handleClick(e, "#news")}
                />
                <SpeedDialAction
                    key="about"
                    icon={<InfoIcon />}
                    tooltipTitle={"イベント概要"}
                    tooltipOpen
                    onClick={(e)=>handleClick(e, "#about")}
                />
            </SpeedDial>
        </Box>
    </>)
}