import Typography from '@mui/material/Typography';
import BasePaper from "./BasePaper";
import Box from '@mui/material/Box';
import Link from "@mui/material/Link";
import Divider from '@mui/material/Divider';

export default function Privacy(props){
    const path = window.location.protocol + "//" + window.location.hostname + (window.location.port ==="" ? "" : ":" +window.location.port)
    return(<>
        <BasePaper 
            mode={props.mode}
            title="プライバシーポリシー"
            breads={<>
                <Link href={path} color='inherit'>TOP</Link> / プライバシーポリシー
                <Divider />
            </>}
            body={<>
                <Box sx={{m:1}}>
                    <>
                    <Typography variant="body1">
                        本プライバシーポリシーは、テトテリトリー準備会（以下「運営団体」といいます。）が提供する同人即売会およびwebサイト(以下「本サービス」といいます。)において、運営団体が本サービスを利用する利用者(以下「利用者」といいます。)のプライバシーを尊重し、利用者の個人情報を適切に管理、運用することを目的として、 本サービスにおける個人情報の取り扱いと責任の所在を明らかにするものです。
                    </Typography>
                    <br />
                    <Typography variant="h5">
                    第1条 定義
                    </Typography>
                    <Typography variant="body2">運営団体では、次の情報を個人情報と定めます。
                    <ol>
                        <li>個人情報の保護に関する法律に規定される、生存する個人に関する情報(氏名、住所、電話番号、その他の特定の個人を識別することができる情報)</li>
                        <li>メールアドレス</li>
                        <li>銀行口座情報</li>
                    </ol>
                    </Typography>
                    <br />
                    <Typography variant="h5">
                    第2条 利用目的
                    </Typography>
                    <Typography variant="body2">運営団体は、個人情報を次の目的で利用します。
                    <ol>
                        <li>物品の発送を含む本サービスの役務の提供</li>
                        <li>本サービスに関する案内、問い合わせへの対応</li>
                        <li>規約に違反する行為への対応</li>
                        <li>個別の識別情報を含まない統計情報の作成</li>
                        <li>その他、上記利用目的に付随する目的</li>
                    </ol>
                    </Typography>
                    <br />
                    <Typography variant="h5">
                    第3条 利用目的
                    </Typography>
                    <Typography variant="body2">運営団体は、利用者が本サービスおよび運営団体が委託する事業者のサービスを利用して入力した範囲に限り、利用者の個人情報を取得します。
                    </Typography>
                    <br />
                    <Typography variant="h5">
                    第4条 第三者への提供
                    </Typography>
                    <Typography variant="body2">運営団体は、利用者の同意を得ず、利用者の個人情報を第三者に提供しません。ただし、次の場合はこの限りではありません。
                    <ol>
                        <li>イベント会場から参加者に関する情報の提供を求められた場合</li>
                        <li>運営団体が、利用者との間で対価の支払い、または受け取りを行う目的で、決済代行事業者に委託する場合</li>
                        <li>運営団体が、利用者に物品を発送する目的で、宅配便事業者に委託する場合</li>
                        <li>運営団体が、利用者にメールの配信を行う目的で、配信サービスを有する事業者に委託する場合</li>
                        <li>運営団体が、法務を遂行する目的で、弁護士に委託する場合</li>
                        <li>その他、運営団体が、本サービスの業務を遂行する目的で、業務の一部を事業者に委託する場合</li>
                    </ol>
                    </Typography>
                    <br />
                    <Typography variant="h5">
                    第5条 取り扱いの制限
                    </Typography>
                    <Typography variant="body2">運営団体は、利用者の同意を得ず、利用目的の達成に必要な範囲を超えて個人情報を取り扱いません。ただし、次の場合はこの限りではありません。
                    <ol>
                        <li>法令に基づく場合</li>
                        <li>人の生命、身体または財産の保護のために必要がある場合であって、利用者の同意を得ることが困難である場合</li>
                        <li>国の機関もしくは地方公共団体またはその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合</li>
                    </ol>
                    </Typography>
                    <br />
                    <Typography variant="h5">
                    第6条 開示
                    </Typography>
                    <Typography variant="body2">運営団体は、利用者から利用者本人の個人情報の開示を求められた場合は、その開示に応じます。ただし、次の場合にはその全部または一部を開示しない場合があります。
                    <ol>
                        <li>法令に違反する恐れがある場合</li>
                        <li>利用者本人または第三者の生命・身体・財産その他の権利を害するおそれがある場合</li>
                        <li>開示に応じることが運営団体の業務遂行に著しい障害を生じさせる場合</li>
                    </ol>
                    </Typography>
                    <br />
                    <Typography variant="h5">
                    第7条 正確性の保証
                    </Typography>
                    <Typography variant="body2">利用者は、個人情報の内容が常に正確であることを運営団体に対して保証し、不正確な個人情報によって発生した損害について、利用者が一切の責任を負うものとします。
                    </Typography>
                    <br />
                    <Typography variant="h5">
                    第8条 Cookie
                    </Typography>
                    <Typography variant="body2">運営団体は、利用者の利便性の向上と利用状況の把握を目的として本サービスでCookieを使用する場合があります。
                    <ol>
                        <li>法令に違反する恐れがある場合</li>
                        <li>利用者本人または第三者の生命・身体・財産その他の権利を害するおそれがある場合</li>
                        <li>開示に応じることが運営団体の業務遂行に著しい障害を生じさせる場合</li>
                    </ol>
                    </Typography>
                    <br />
                    <Typography variant="h5">
                    第9条 免責
                    </Typography>
                    <Typography variant="body2">運営団体は、第三者による個人情報の取り扱いに関し、次に掲げる場合には一切の責任を負いません。
                    <ol>
                        <li>利用者が、本サービスの機能または別の手段を用いて第三者に個人情報を明らかにした場合</li>
                        <li>第三者が、外部の手段を用いて取得した利用者の個人情報を本サービスの機能を用いて明らかにした場合</li>
                    </ol>
                    </Typography>
                    <br />
                    <Typography variant="h5">
                    第10条 改定
                    </Typography>
                    <Typography variant="body2">運営団体は、本プライバシーポリシーについて、事前に利用者へ適切な周知を行うことで、利用者の同意を得ず、改定できるものとします。
                    </Typography>
                    <br />
                    </>
                </Box>
            </>}
        />
    </>)
}