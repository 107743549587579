import * as React from 'react';
import axios from 'axios';
import Typography from '@mui/material/Typography';
import BasePaper from "./BasePaper";
import Box from '@mui/material/Box';
import Link from "@mui/material/Link";
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Unstable_Grid2';
import Paper from '@mui/material/Paper';
import Chip from '@mui/material/Chip';
import TwitterIcon from '@mui/icons-material/Twitter';
import IconButton from '@mui/material/IconButton';

export default function CircleList(props){
    const path = window.location.protocol + "//" + window.location.hostname + (window.location.port ==="" ? "" : ":" +window.location.port)
    const csvPath = path + "/static/circle.csv"
    const [circles, setCircles] = React.useState([]);
    const ReadCsv = async() => {
        await(axios.get(csvPath)).then((response)=>{
            const csvData = response.data.split("\r\n").map((row)=>row.split(","))
            setCircles(csvData)
        })
    }
    ReadCsv()
    return(<>
        <BasePaper 
            mode={props.mode}
            title="参加サークル一覧"
            breads={<>
                <Link href={path} color='inherit'>TOP</Link> / サークル一覧
                <Divider />
            </>}
            body={<>
            <Box sx={{m:1}}>
                <Link href={path + "/static/haichizu.pdf"} target="_blank" variant="h4">サークル配置図</Link>
            </Box>
            <Divider />
            <Box sx={{m:1}}>
                <Grid container spacing={2} sx={{ flexGrow: 1 }}>
                    {circles.map((circle)=><>
                        {circle[0]!=="落選" && circle[0]!==""&& circle[0]!=="当選落選" && <Paper sx={{width:300,height:470,m:2,p:1}}>
                            <img src={path + "/static/img/circlecut/"+circle[0]+"_" + circle[1] + ".png"} style={{width:'100%'}} alt={circle[1]+" サークルカット"}/><br />
                            <Box sx={{justifyContent:'space-between',display:'flex'}}>
                                <Typography variant='subtitle1'>{circle[0]}<br />{circle[1]}</Typography>
                            {circle[5]!==""&&<>
                                <Link size="small" href={circle[5]} target="_blank">
                                    (外部サイト)
                                </Link>
                            </>}
                            </Box>
                            <Divider />
                            <Box sx={{display:'flex',flexWrap:'wrap'}}>
                                {circle[6]!=="" ? <>
                                    <Chip
                                        icon={<TwitterIcon />}
                                        label={circle[3]}
                                        component="a"
                                        href={ConvertTwitterUrl(circle[6])}
                                        target="_blank"
                                        size="small"
                                        clickable
                                        sx={{m:1}}
                                    />
                                </>:<>
                                    <Chip label={circle[3]} size="small" sx={{m:1}}/>
                                </>}
                                {[...Array(5)].map((_, i)=><>{
                                        circle[8+i*2+1]!=="" ? <>
                                        {circle[8+i*2]!==circle[3] &&
                                        <Chip
                                            icon={<TwitterIcon />}
                                            label={circle[8+i*2]}
                                            component="a"
                                            href={ConvertTwitterUrl(circle[8+i*2+1])}
                                            target="_blank"
                                            size="small"
                                            clickable
                                            sx={{m:1}}
                                        />}
                                    </>:<>
                                        {circle[8+i*2]!==""&&circle[8+i*2]!==circle[3] &&
                                            <Chip label={circle[8+i*2]} size="small" sx={{m:1}}/>
                                        }
                                    </>
                                }</>)}
                            </Box>
                        </Paper>}
                    </>)}
                </Grid>

            </Box>
            </>}
        />
    </>)
}

function ConvertTwitterUrl(value){
    let url = value.split("?")[0]
    url = url.split("/").slice(-1)[0]
    url = url.replace("@","")
    return "https://twitter.com/"+url
}