import Box from '@mui/material/Box';
import { red,grey } from '@mui/material/colors';

export default function FootDress(props){
    return(
        <Box sx={{
            width:'100%',
            background:red[300],
            height:30,
            p:0,
            m:0}}>
                
            <Box sx={{
                height:25,
                borderBottomColor:grey[900],
                borderBottomWidth:5,
                borderBottomStyle:props.mode==='light' ?'dashed':'none'}}>
            </Box>
        </Box>
    )
}